import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONSTANTS } from '@app/constants/app.constant';
import { GA_EVENTS } from '@app/constants/google-analytics-events.constant';
import { LOCAL_STORAGE_CONSTANT } from '@app/constants/localstorage.constant';
import { APP_ROUTE, AUTH_ROUTE } from '@constants/routes/route-paths.constant';
import { NOTICE_TYPE } from '@dvm/components';
import { environment } from '@environment/environment';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { AlertToasterService } from '../alert-toaster/alert-toaster.service';
import { CurrentUserStateService } from '../current-user/current-user-state/current-user-state.service';
import { AnalyticsService } from '../google-analytics/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private logoutWindowRef: Window | null = null;

  private toasterService = inject(AlertToasterService);
  private userState = inject(CurrentUserStateService);
  private localStorageService = inject(LocalStorageService);
  private router = inject(Router);
  private analyticsService = inject(AnalyticsService);

  private windowRef: Window | null = null;

  login(url: string) {
    if (this.windowRef) {
      if (this.windowRef.closed) this.closeWindow();
      else {
        this.windowRef.focus();
        return;
      }
    }
    this.loginWithExternalProvider(url).then(
      (token) => {
        this.localStorageService.set(LOCAL_STORAGE_CONSTANT.LOGIN_TOKEN, token);
        this.userState.setClientList(true);
      },
      (error) => {
        this.toasterService.displaySnackBarWithoutTranslation(
          error,
          NOTICE_TYPE.DANGER,
        );
        this.logout();
      },
    );
  }

  loginWithExternalProvider(oauthUrl: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.windowRef = window.open(
        oauthUrl,
        '_blank',
        'location=no,width=700,height=400',
      );

      const handleMessage = (event: MessageEvent) => {
        this.closeWindow();
        if (event.data.token) {
          resolve(event.data.token);
        } else {
          reject(event.data.message);
        }
      };
      window.addEventListener('message', handleMessage);
    });
  }

  private closeWindow(): void {
    if (this.windowRef !== null) {
      this.windowRef.close();
      this.windowRef = null;
    }
  }

  logoutExternal() {
    this.logoutWindowRef = window.open(
      `${environment.hostName}/${APP_CONSTANTS.LOGOUT_DATAVXT}`,
      '_blank',
      'location=no,width=500,height=400',
    );
    if (this.logoutWindowRef) {
      this.logoutWindowRef.addEventListener('pagehide', () => {
        setTimeout(() => {
          this.logoutWindowRef.close();
          this.logoutWindowRef = null;
        }, 0);
      });
    }
  }

  logoutUserSession(redirect = true): void {
    this.userState.selectedUser.set(null);
    this.localStorageService.clear();
    this.analyticsService.trackEvent(GA_EVENTS.LOGOUT);
    if (redirect)
      this.router.navigate([`${APP_ROUTE.AUTH}/${AUTH_ROUTE.LOGIN}`]);
  }

  logout(redirect = true): void {
    this.logoutExternal();
    this.logoutUserSession(redirect);
  }
}
